export const LOGIN_USER = "LOGIN_USER";
export const REGISTER_USER = "REGISTER_USER";
export const ERROR_MESSAGE = "REGISTER_MESSAGE";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const REQUEST_PASSWORD_UPDATE = "REQUEST_PASSWORD_UPDATE";
export const RESEND_OTP = "RESEND_OTP";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const NEWSLETTER_SUBSCRIPTION = "NEWSLETTER_SUBSCRIPTION";
export const SEND_MESSAGE = "SEND_MESSAGE";




