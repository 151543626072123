import React from "react";
import "./App.less";
import "./styles/login.less"
import AppContainer from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./common/component/scrollToTop";
import { Provider } from "react-redux";
import configureStore from "./common/store";

const store = configureStore();
function App() {
  return (
    <Provider store={store}>
        <div className="App">
      <Router>
          <ScrollToTop />
          <AppContainer />
      </Router>
        </div>
    </Provider>
  );
}

export default App;
