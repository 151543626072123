import * as actiontypes from "../action/actionTypes";

const initialState = {}

const Register = (state = initialState, action) => {
  switch (action.type) {
    case actiontypes.REGISTER_USER: {
      return { ...state, user: action.payload };
    }
    case actiontypes.ERROR_MESSAGE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case actiontypes.LOGIN_USER: {
      return {
        ...state,
        login: action.payload,
      };
    }
    case actiontypes.AUTHENTICATE_USER: {
      return {
        ...state,
        canLogin: action.payload,
      };
    }
    case actiontypes.OTP_VERIFICATION: {
      return {
        ...state,
       otpVerification: action.payload,
      };
    }
    case actiontypes.REQUEST_PASSWORD_UPDATE: {
      return {
        ...state,
        requestNewPassword: action.payload,
      };
    }
    case actiontypes.RESET_PASSWORD: {
      return {
        ...state,
        passwordReset: action.payload,
      };
    }
    case actiontypes.RESEND_OTP: {
      return {
        ...state,
        newOtpInfo: action.payload,
      };
    }
    case actiontypes.NEWSLETTER_SUBSCRIPTION: {
      return {
        ...state,
        newsletterSubscription: action.payload,
      };
    }
    case actiontypes.SEND_MESSAGE: {
      return {
        ...state,
        contactMessage: action.payload,
      };
    }
    default:
      return state;
  }
};
export default Register