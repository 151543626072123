import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../module/authentication/common/reducer";
import thunk from "redux-thunk";

// const initialState = {};

const configureStore = (initialState) =>{
  return createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunk)
    )
  );
}
export default configureStore;
